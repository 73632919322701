@tailwind base;
@tailwind components;
@tailwind utilities;

.rdp-vhidden {
  @apply hidden;
}

.required:after {
  content: ' *';
  color: red;
}

.streched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

:host(.nextjs-toast-errors-parent) {
  display: none !important;
}

:-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 9999px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #a3a3a3;
  border-radius: 9999px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-corner {
  background: #f5f5f4;
}

::-webkit-scrollbar-track {
  background: transparent;
}

body:hover::-webkit-scrollbar-thumb {
  background: #d6d3d1;
  border-radius: 9999px;
  border: 4px solid transparent;
  background-clip: padding-box;
}

body.scrollable {
  overflow-y: scroll;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :root {
    --blue-choice: hsl(226 71% 40%);

    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    --chart-expired: 12 76% 61%;
    --chart-completed: 173 58% 39%;

    --chart-estimated: 211 100% 50%;
    --chart-statistical-measure: 134 61% 41%;
    --chart-trendline: 354 70% 54%;

    --chart-trendline-variant: 12 76% 61%;
    --chart-metric-variant: 173 52% 40%;
    --chart-estimated-variant: 47 11% 49%;
  }

  .dark {
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;

    --blue-choice: hsl(226 71% 40%);

    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  :root {
    --sidebar-background: 60 5% 96%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --sidebar-background: 60 5% 96%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground min-h-screen;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

.theme-zinc-light {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 240 5.9% 10%;
  --primary-foreground: 0 0% 98%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 240 5.9% 10%;
}

.theme-zinc-dark {
  --background: 240 10% 3.9%;
  --foreground: 0 0% 98%;

  --muted: 240 3.7% 15.9%;
  --muted-foreground: 240 5% 64.9%;

  --popover: 240 10% 3.9%;
  --popover-foreground: 0 0% 98%;

  --card: 240 10% 3.9%;
  --card-foreground: 0 0% 98%;

  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;

  --primary: 0 0% 98%;
  --primary-foreground: 240 5.9% 10%;

  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 240 3.7% 15.9%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 0% 98%;

  --ring: 240 4.9% 83.9%;
}

.theme-slate-light {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;

  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --ring: 222.2 84% 4.9%;
}

.theme-slate-dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;

  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;

  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;

  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;

  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;

  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;

  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;

  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;

  --ring: 212.7 26.8% 83.9;
}

.theme-stone-light {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;

  --muted: 60 4.8% 95.9%;
  --muted-foreground: 25 5.3% 44.7%;

  --popover: 0 0% 100%;
  --popover-foreground: 20 14.3% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 20 14.3% 4.1%;

  --border: 20 5.9% 90%;
  --input: 20 5.9% 90%;

  --primary: 24 9.8% 10%;
  --primary-foreground: 60 9.1% 97.8%;

  --secondary: 60 4.8% 95.9%;
  --secondary-foreground: 24 9.8% 10%;

  --accent: 60 4.8% 95.9%;
  --accent-foreground: 24 9.8% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 20 14.3% 4.1%;
}

.theme-stone-dark {
  --background: 20 14.3% 4.1%;
  --foreground: 60 9.1% 97.8%;

  --muted: 12 6.5% 15.1%;
  --muted-foreground: 24 5.4% 63.9%;

  --popover: 20 14.3% 4.1%;
  --popover-foreground: 60 9.1% 97.8%;

  --card: 20 14.3% 4.1%;
  --card-foreground: 60 9.1% 97.8%;

  --border: 12 6.5% 15.1%;
  --input: 12 6.5% 15.1%;

  --primary: 60 9.1% 97.8%;
  --primary-foreground: 24 9.8% 10%;

  --secondary: 12 6.5% 15.1%;
  --secondary-foreground: 60 9.1% 97.8%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 60 9.1% 97.8%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 24 5.7% 82.9%;
}

.theme-gray-light {
  --background: 0 0% 100%;
  --foreground: 224 71.4% 4.1%;

  --muted: 220 14.3% 95.9%;
  --muted-foreground: 220 8.9% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 224 71.4% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 224 71.4% 4.1%;

  --border: 220 13% 91%;
  --input: 220 13% 91%;

  --primary: 220.9 39.3% 11%;
  --primary-foreground: 210 20% 98%;

  --secondary: 220 14.3% 95.9%;
  --secondary-foreground: 220.9 39.3% 11%;

  --accent: 220 14.3% 95.9%;
  --accent-foreground: 220.9 39.3% 11%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 20% 98%;

  --ring: 224 71.4% 4.1%;
}

.theme-gray-dark {
  --background: 224 71.4% 4.1%;
  --foreground: 210 20% 98%;

  --muted: 215 27.9% 16.9%;
  --muted-foreground: 217.9 10.6% 64.9%;

  --popover: 224 71.4% 4.1%;
  --popover-foreground: 210 20% 98%;

  --card: 224 71.4% 4.1%;
  --card-foreground: 210 20% 98%;

  --border: 215 27.9% 16.9%;
  --input: 215 27.9% 16.9%;

  --primary: 210 20% 98%;
  --primary-foreground: 220.9 39.3% 11%;

  --secondary: 215 27.9% 16.9%;
  --secondary-foreground: 210 20% 98%;

  --accent: 215 27.9% 16.9%;
  --accent-foreground: 210 20% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 20% 98%;

  --ring: 216 12.2% 83.9%;
}

.theme-neutral-light {
  --background: 0 0% 100%;
  --foreground: 0 0% 3.9%;

  --muted: 0 0% 96.1%;
  --muted-foreground: 0 0% 45.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 0 0% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 0 0% 3.9%;

  --border: 0 0% 89.8%;
  --input: 0 0% 89.8%;

  --primary: 0 0% 9%;
  --primary-foreground: 0 0% 98%;

  --secondary: 0 0% 96.1%;
  --secondary-foreground: 0 0% 9%;

  --accent: 0 0% 96.1%;
  --accent-foreground: 0 0% 9%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 0% 3.9%;
}

.theme-neutral-dark {
  --background: 0 0% 3.9%;
  --foreground: 0 0% 98%;

  --muted: 0 0% 14.9%;
  --muted-foreground: 0 0% 63.9%;

  --popover: 0 0% 3.9%;
  --popover-foreground: 0 0% 98%;

  --card: 0 0% 3.9%;
  --card-foreground: 0 0% 98%;

  --border: 0 0% 14.9%;
  --input: 0 0% 14.9%;

  --primary: 0 0% 98%;
  --primary-foreground: 0 0% 9%;

  --secondary: 0 0% 14.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 0 0% 14.9%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 0% 83.1%;
}

.theme-red-light {
  --background: 0 0% 100%;
  --foreground: 0 0% 3.9%;

  --muted: 0 0% 96.1%;
  --muted-foreground: 0 0% 45.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 0 0% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 0 0% 3.9%;

  --border: 0 0% 89.8%;
  --input: 0 0% 89.8%;

  --primary: 0 72.2% 50.6%;
  --primary-foreground: 0 85.7% 97.3%;

  --secondary: 0 0% 96.1%;
  --secondary-foreground: 0 0% 9%;

  --accent: 0 0% 96.1%;
  --accent-foreground: 0 0% 9%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 72.2% 50.6%;
}

.theme-red-dark {
  --background: 0 0% 3.9%;
  --foreground: 0 0% 98%;

  --muted: 0 0% 14.9%;
  --muted-foreground: 0 0% 63.9%;

  --popover: 0 0% 3.9%;
  --popover-foreground: 0 0% 98%;

  --card: 0 0% 3.9%;
  --card-foreground: 0 0% 98%;

  --border: 0 0% 14.9%;
  --input: 0 0% 14.9%;

  --primary: 0 72.2% 50.6%;
  --primary-foreground: 0 85.7% 97.3%;

  --secondary: 0 0% 14.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 0 0% 14.9%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 0% 98%;

  --ring: 0 72.2% 50.6%;
}

.theme-rose-light {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 346.8 77.2% 49.8%;
  --primary-foreground: 355.7 100% 97.3%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 346.8 77.2% 49.8%;
}

.theme-rose-dark {
  --background: 20 14.3% 4.1%;
  --foreground: 0 0% 95%;

  --muted: 0 0% 15%;
  --muted-foreground: 240 5% 64.9%;

  --popover: 0 0% 9%;
  --popover-foreground: 0 0% 95%;

  --card: 24 9.8% 10%;
  --card-foreground: 0 0% 95%;

  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;

  --primary: 346.8 77.2% 49.8%;
  --primary-foreground: 355.7 100% 97.3%;

  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;

  --ring: 346.8 77.2% 49.8%;
}

.theme-orange-light {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;

  --muted: 60 4.8% 95.9%;
  --muted-foreground: 25 5.3% 44.7%;

  --popover: 0 0% 100%;
  --popover-foreground: 20 14.3% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 20 14.3% 4.1%;

  --border: 20 5.9% 90%;
  --input: 20 5.9% 90%;

  --primary: 24.6 95% 53.1%;
  --primary-foreground: 60 9.1% 97.8%;

  --secondary: 60 4.8% 95.9%;
  --secondary-foreground: 24 9.8% 10%;

  --accent: 60 4.8% 95.9%;
  --accent-foreground: 24 9.8% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 24.6 95% 53.1%;
}

.theme-orange-dark {
  --background: 20 14.3% 4.1%;
  --foreground: 60 9.1% 97.8%;

  --muted: 12 6.5% 15.1%;
  --muted-foreground: 24 5.4% 63.9%;

  --popover: 20 14.3% 4.1%;
  --popover-foreground: 60 9.1% 97.8%;

  --card: 20 14.3% 4.1%;
  --card-foreground: 60 9.1% 97.8%;

  --border: 12 6.5% 15.1%;
  --input: 12 6.5% 15.1%;

  --primary: 20.5 90.2% 48.2%;
  --primary-foreground: 60 9.1% 97.8%;

  --secondary: 12 6.5% 15.1%;
  --secondary-foreground: 60 9.1% 97.8%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 60 9.1% 97.8%;

  --destructive: 0 72.2% 50.6%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 20.5 90.2% 48.2%;
}

.theme-green-light {
  --background: 0 0% 100%;
  --foreground: 240 10% 3.9%;

  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;

  --card: 0 0% 100%;
  --card-foreground: 240 10% 3.9%;

  --border: 240 5.9% 90%;
  --input: 240 5.9% 90%;

  --primary: 142.1 76.2% 36.3%;
  --primary-foreground: 355.7 100% 97.3%;

  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;

  --accent: 240 4.8% 95.9%;
  --accent-foreground: 240 5.9% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;

  --ring: 142.1 76.2% 36.3%;
}

.theme-green-dark {
  --background: 20 14.3% 4.1%;
  --foreground: 0 0% 95%;

  --muted: 0 0% 15%;
  --muted-foreground: 240 5% 64.9%;

  --popover: 0 0% 9%;
  --popover-foreground: 0 0% 95%;

  --card: 24 9.8% 10%;
  --card-foreground: 0 0% 95%;

  --border: 240 3.7% 15.9%;
  --input: 240 3.7% 15.9%;

  --primary: 142.1 70.6% 45.3%;
  --primary-foreground: 144.9 80.4% 10%;

  --secondary: 240 3.7% 15.9%;
  --secondary-foreground: 0 0% 98%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 0 0% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 0 85.7% 97.3%;

  --ring: 142.4 71.8% 29.2%;
}

.theme-blue-light {
  --background: 0 0% 100%;
  --foreground: 222.2 84% 4.9%;

  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;

  --popover: 0 0% 100%;
  --popover-foreground: 222.2 84% 4.9%;

  --card: 0 0% 100%;
  --card-foreground: 222.2 84% 4.9%;

  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;

  --primary: 221.2 83.2% 53.3%;
  --primary-foreground: 210 40% 98%;

  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;

  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 40% 98%;

  --ring: 221.2 83.2% 53.3%;
}

.theme-blue-dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;

  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;

  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;

  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;

  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;

  --primary: 217.2 91.2% 59.8%;
  --primary-foreground: 222.2 47.4% 11.2%;

  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;

  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;

  --ring: 224.3 76.3% 48%;
}

.theme-yellow-light {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;

  --muted: 60 4.8% 95.9%;
  --muted-foreground: 25 5.3% 44.7%;

  --popover: 0 0% 100%;
  --popover-foreground: 20 14.3% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 20 14.3% 4.1%;

  --border: 20 5.9% 90%;
  --input: 20 5.9% 90%;

  --primary: 47.9 95.8% 53.1%;
  --primary-foreground: 26 83.3% 14.1%;

  --secondary: 60 4.8% 95.9%;
  --secondary-foreground: 24 9.8% 10%;

  --accent: 60 4.8% 95.9%;
  --accent-foreground: 24 9.8% 10%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 20 14.3% 4.1%;
}

.theme-yellow-dark {
  --background: 20 14.3% 4.1%;
  --foreground: 60 9.1% 97.8%;

  --muted: 12 6.5% 15.1%;
  --muted-foreground: 24 5.4% 63.9%;

  --popover: 20 14.3% 4.1%;
  --popover-foreground: 60 9.1% 97.8%;

  --card: 20 14.3% 4.1%;
  --card-foreground: 60 9.1% 97.8%;

  --border: 12 6.5% 15.1%;
  --input: 12 6.5% 15.1%;

  --primary: 47.9 95.8% 53.1%;
  --primary-foreground: 26 83.3% 14.1%;

  --secondary: 12 6.5% 15.1%;
  --secondary-foreground: 60 9.1% 97.8%;

  --accent: 12 6.5% 15.1%;
  --accent-foreground: 60 9.1% 97.8%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 60 9.1% 97.8%;

  --ring: 35.5 91.7% 32.9%;
}

.theme-violet-light {
  --background: 0 0% 100%;
  --foreground: 224 71.4% 4.1%;

  --muted: 220 14.3% 95.9%;
  --muted-foreground: 220 8.9% 46.1%;

  --popover: 0 0% 100%;
  --popover-foreground: 224 71.4% 4.1%;

  --card: 0 0% 100%;
  --card-foreground: 224 71.4% 4.1%;

  --border: 220 13% 91%;
  --input: 220 13% 91%;

  --primary: 262.1 83.3% 57.8%;
  --primary-foreground: 210 20% 98%;

  --secondary: 220 14.3% 95.9%;
  --secondary-foreground: 220.9 39.3% 11%;

  --accent: 220 14.3% 95.9%;
  --accent-foreground: 220.9 39.3% 11%;

  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 210 20% 98%;

  --ring: 262.1 83.3% 57.8%;
}

.theme-violet-dark {
  --background: 224 71.4% 4.1%;
  --foreground: 210 20% 98%;

  --muted: 215 27.9% 16.9%;
  --muted-foreground: 217.9 10.6% 64.9%;

  --popover: 224 71.4% 4.1%;
  --popover-foreground: 210 20% 98%;

  --card: 224 71.4% 4.1%;
  --card-foreground: 210 20% 98%;

  --border: 215 27.9% 16.9%;
  --input: 215 27.9% 16.9%;

  --primary: 263.4 70% 50.4%;
  --primary-foreground: 210 20% 98%;

  --secondary: 215 27.9% 16.9%;
  --secondary-foreground: 210 20% 98%;

  --accent: 215 27.9% 16.9%;
  --accent-foreground: 210 20% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 20% 98%;

  --ring: 263.4 70% 50.4%;
}
